
  import Vue from 'vue';
  import image1 from '@/assets/svg/homepage_1.svg';
  import image2 from '@/assets/svg/homepage_2.svg';
  import image3 from '@/assets/svg/homepage_3.svg';

  export default Vue.extend({
    name: 'es-home-how-it-works',
    data: () => ({
      image1,
      image2,
      image3,
    }),
  });
