<template>
  <div class="content">
    <div class="hero home-hero">
      <div class="opacity-mask">
        <div class="container">
          <div class="row justify-content-lg-start">
            <div class="col-xl-6 col-lg-8">
              <h1 class="mb-4">{{ $t('views.home.title') }}</h1>
              <form>
                <div class="row no-gutters custom-search-input">
                  <div class="col-lg-9">
                    <es-address-search :home-page="true" />
                  </div>
                  <div class="col-lg-3">
                    <button class="btn_1 custom-button" type="button" @click="$router.push('/servicii')">{{ $t('generic.reserve') }}</button>
                  </div>
                </div>
                <!-- /row -->
              </form>
            </div>
          </div>
          <!-- /row -->
        </div>
      </div>
      <div class="wave hero" />
    </div>
    <!-- /hero_single -->
    <es-download-app />
    <es-how-it-works />
    <es-more-details />
    <es-why-us />
    <es-reserve-from-app />
  </div>
</template>

<script>
  import Vue from 'vue';
  import { AddressSearch } from '@/components/shared/address-search';
  import { DownloadApp } from '@/components/shared/download-app';
  import HowItWorks from './HowItWorks.vue';
  import MoreDetails from './MoreDetails.vue';
  import ReserveFromApp from './ReserveFromApp.vue';
  import WhyUs from './WhyUs.vue';

  export default Vue.extend({
    name: 'es-home',

    /* eslint-disable */
    metaInfo: {
      title: 'Comanda servicii de masaj, beauty si fitness la usa ta cu Ease',
      titleTemplate: '',
      description: 'Alege din 50+ servicii profesionale, selecteaza data si ora iar profesionistii Ease ajung rapid la tine cu toate echipamentele necesare.',
      meta: [
        { name: 'description', content: 'Alege din 50+ servicii profesionale, selecteaza data si ora iar profesionistii Ease ajung rapid la tine cu toate echipamentele necesare.' },
        { name: 'og:title', content: 'Comanda servicii de masaj, beauty si fitness la usa ta cu Ease' },
        { name: 'og:description', content: 'Alege din 50+ servicii profesionale, selecteaza data si ora iar profesionistii Ease ajung rapid la tine cu toate echipamentele necesare' },
        { name: 'og:image', content: require('@/assets/jpg/fb-thumb-homepage.jpg'), },
      ],
    },

    components: {
      'es-address-search': AddressSearch,
      'es-download-app': DownloadApp,
      'es-how-it-works': HowItWorks,
      'es-more-details': MoreDetails,
      'es-reserve-from-app': ReserveFromApp,
      'es-why-us': WhyUs,
    },

    mounted() {
      window.initEase();
    },
  });
</script>
